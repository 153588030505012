import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../styles/pages/index.css';

const IndexPage = () => (
    <Layout requireLogin className="landing-page container">
        <SEO title="Home" />
        <h1>Welcome to EnsoData!</h1>
        <div className="content">
            This is a demonstration of the EnsoData Salesforce migration
            product. It painlessly migrates{' '}
            <strong>Contact</strong>,{' '}
            <strong>Account</strong>,{' '}
            <strong>User</strong>, and{' '}
            <strong>RecordType</strong>{' '}
            objects from a source Salesforce org to a target Salesforce org,
            preserving all custom fields and relationships.
            {/* TODO: ^ Is the preservation of all custom fields and
                relationships really true? o_O */}
        </div>
        <div className="btns-primary">
            <Link className="btn btn-primary btn-large" to="/registration/">
                Lets Go!
            </Link>
        </div>
    </Layout>
);

export default IndexPage;
